/* Footer Styling */
.footer {
    background-color: #ffffff;
    color: #333333;
    padding: 40px 20px;
    font-family: 'Montserrat', sans-serif;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-section {
    flex: 1 1 220px;
    max-width: 24%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.footer-section h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #ff0066;
}

.footer-section p,
.footer-section ul,
.footer-section a {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 8px;
}

.footer-section ul li a {
    text-decoration: none;
    color: #555555;
    position: relative;
    transition: color 0.3s, text-decoration-color 0.3s;
}

.footer-section ul li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: #ff0066;
    left: 0;
    bottom: -2px;
    transition: width 0.3s ease;
}

.footer-section ul li a:hover {
    color: #ff0066;
}

.footer-section ul li a:hover::after {
    width: 100%;
}

/* Contact Info Styling */
.contact-info {
    display: flex;
    flex-direction: column;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.contact-item svg {
    color: #ff0066;
    font-size: 1rem;
    margin-right: 15px;
    transform: translateY(-8px);
}

.footer-section.contact-info h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #ff0066;
    font-weight: normal;
}

.contact-item p {
    margin: 0;
    font-size: 1rem;
}

.contact-item a {
    color: #555555;
    text-decoration: none;
    position: relative;
    transition: color 0.3s, text-decoration-color 0.3s;
}

.contact-item a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: #ff0066;
    left: 0;
    bottom: -2px;
    transition: width 0.3s ease;
}

.contact-item a:hover {
    color: #ff0066;
}

.contact-item a:hover::after {
    width: 100%;
}

/* Social Icons */
.social-icons {
    display: flex;
    gap: 20px;
}

.social-icons a {
    color: #ff0066;
    font-size: 1.8rem;
    transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
    color: #333333;
    transform: scale(1.1);
}

/* Footer Bottom Styling */
.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    border-top: 1px solid #ff0066;
    padding-top: 10px;
}

.footer-bottom p {
    margin: 0;
    text-align: center;
}

.footer-bottom a {
    color: #555555;
    text-decoration: none;
    position: relative;
    transition: color 0.3s, text-decoration-color 0.3s;
}

.footer-bottom a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: #ff0066;
    left: 0;
    bottom: -2px;
    transition: width 0.3s ease;
}

.footer-bottom a:hover {
    color: #ff0066;
}

.footer-bottom a:hover::after {
    width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        padding: 0;
    }

    .footer-section {
        max-width: 100%;
        margin-bottom: 0;
        padding: 0 10px;
    }

    .footer-section h3 {
        font-size: 1.2rem;
        margin-bottom: 5px;
    }

    .footer-section p,
    .footer-section ul,
    .footer-section a {
        font-size: 0.9rem;
        margin-bottom: 5px;
    }

    .footer-section:not(:last-child) {
        margin-bottom: calc(-120px + (10px * var(--num-elements)));
    }

    .contact-item {
        margin-bottom: -10px;
    }

    .social-icons {
        justify-content: flex-start;
        gap: 15px;
    }

    .social-icons a {
        font-size: 1.6rem;
    }

    .footer-bottom {
        align-items: flex-start;
        text-align: left;
        padding-top: 15px;
        margin-top: -150px;
    }

    .footer-bottom p {
        margin-bottom: 5px;
    }
}

/* Dynamic Margin Adjustment */
.footer-section.menu {
    --num-elements: 12;
}

.footer-section.company-info {
    --num-elements: 3;
}

.footer-section.opening-hours {
    --num-elements: 1;
}

.footer-section.contact-info {
    --num-elements: 3;
}

.footer-section.social-media {
    --num-elements: 1;
}
