/* src/AboutUs.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Keyframes for Zoom Effect */
@keyframes zoomEffect {
    0% {
        background-size: 80%; /* Start zoomed in */
        background-position: center; /* Center the image */
    }
    100% {
        background-size: 100%; /* End zoomed out */
        background-position: center; /* Ensure the final position is centered */
    }
}

/* About Us Page Section */
.about-us-page-section {
    position: relative;
    height: 60vh; /* Maintain height */
    background-image: url('./about-us-page-1.jpg'); /* Background image */
    background-size: 60%; /* Initial zoomed-in size */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color for contrast */
    text-align: center; /* Center-align the text content */
    padding: 0 20px; /* Padding for smaller screens */
    animation: zoomEffect 2s forwards; /* Apply the one-time zoom effect */
}

.about-us-content {
    max-width: 800px; /* Limit content width for readability */
    margin: 0 auto; /* Center content horizontally */
}

.about-us-content h1 {
    font-size: 4rem; /* Large font size for the heading */
    font-family: 'Montserrat', sans-serif; /* Brand font */
    font-weight: 600; /* Semi-bold for a strong presence */
    line-height: 1.2; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below the heading */
}

.about-us-content p {
    font-size: 1.2rem; /* Slightly larger text for readability */
    font-family: 'Montserrat', sans-serif; /* Consistent font family */
    font-weight: 300; /* Light font weight */
    line-height: 1.8; /* Comfortable line spacing */
    margin-bottom: 20px; /* Space below paragraphs */
}

/* About Us Section */
.about-us-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    background-color: #f8f9fa;
}

.about-us-section .about-us-image {
    flex: 1;
    max-width: 46%;
    order: 2; /* Image on the left */
}

.about-us-section .about-us-content {
    flex: 1;
    max-width: 43%;
    padding-left: 20px; /* Space between image and text */
    order: 2; /* Text on the right */
}

.about-us-section .about-us-content h2 {
    font-family: 'Playfair Display', serif; /* Updated font */
    font-size: 2.5rem;
    font-weight: 600; /* Adjusted to a slightly less bold weight */
    color: #2c3e50; /* More elegant color */
    margin-bottom: 20px;
}

.about-us-section .about-us-content p {
    font-family: 'Montserrat', sans-serif; /* Updated font */
    font-size: 1.2rem;
    line-height: 1.8; /* Increased for better readability */
    color: #555; /* A softer gray for a professional look */
    margin-bottom: 20px;
}

.read-more-link {
    font-family: 'Montserrat', sans-serif; /* Consistent font */
    font-weight: 600; /* A bit bolder */
    font-size: 1rem;
    text-decoration: none;
    color: #ff0066;
    display: inline-flex;
    align-items: center;
}

.read-more-link .arrow {
    font-size: 1.5rem;
    margin-left: 10px;
    transition: margin-left 0.3s ease;
}

.read-more-link:hover .arrow {
    margin-left: 20px;
}

.about-us-section .about-us-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

@media (max-width: 768px) {
    .about-us-page-section {
        height: auto; /* Allow height to adjust based on content */
        padding: 40px 20px; /* Adjust padding for smaller screens */
    }

    .about-us-content h1 {
        font-size: 2.5rem; /* Smaller font size for mobile */
    }

    .about-us-content p {
        font-size: 1rem; /* Slightly smaller text for mobile */
    }

    .about-us-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px; /* Adjust padding */
    }

    .about-us-section .about-us-content,
    .about-us-section .about-us-image {
        max-width: 100%;
        padding: 0;
    }

    .about-us-section .about-us-content {
        padding-top: 20px; /* Space between image and text on smaller screens */
    }
}

/* New About Us Journey Section */
.about-us-journey-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    background-color: #ffffff; /* White background */
}

.about-us-journey-content {
    flex: 1;
    max-width: 40%;
    order: 1; /* Text on the left */
    font-family: 'Montserrat', sans-serif;
}

.about-us-journey-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.about-us-journey-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.about-us-journey-image {
    flex: 1;
    max-width: 48%;
    order: 2; /* Image on the right */
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-journey-image img {
    width: 100%;
    height: auto; /* Adjust height to maintain aspect ratio */
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

@media (max-width: 768px) {
    .about-us-journey-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px; /* Adjust padding */
    }

    .about-us-journey-content,
    .about-us-journey-image {
        max-width: 100%;
        padding: 0;
    }

    .about-us-journey-content {
        padding-top: 20px; /* Space between text and image on smaller screens */
    }
}

/* New About Us Team Section */
.about-us-team-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    background-color: #f9f9f9; /* Light background color */
}

.about-us-team-images {
    flex: 1;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    order: 1; /* Images on the left */
}

.team-member {
    text-align: center;
    margin-bottom: 30px;
}

.team-member img {
    width: 150px; /* Size of the circular images */
    height: 150px;
    border-radius: 50%; /* Make the image circular */
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease-in-out;
}

.team-member img:hover {
    transform: scale(1.1); /* Enlarge the image slightly on hover */
}

.team-member-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #333;
    margin-top: 10px;
    font-weight: 600; /* Bold name */
}

.team-member-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    color: #777;
    margin-top: 5px;
    font-weight: 400; /* Normal weight for title */
}

.about-us-team-content {
    flex: 1;
    max-width: 35%;
    order: 2; /* Text on the right */
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px; /* Added padding for alignment */
}

.about-us-team-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.about-us-team-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .about-us-team-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px; /* Adjust padding */
    }

    .about-us-team-images,
    .about-us-team-content {
        max-width: 100%;
        padding: 0;
    }

    .about-us-team-content {
        padding-top: 20px; /* Space between images and text on smaller screens */
    }
}

/* AOS Animations */
[data-aos="fade-right"] {
    opacity: 0;
    transform: translateX(-50px);
    transition-property: opacity, transform;
    transition-duration: 1s;
}

[data-aos="fade-left"] {
    opacity: 0;
    transform: translateX(50px);
    transition-property: opacity, transform;
    transition-duration: 1s;
}

[data-aos].aos-animate {
    opacity: 1;
    transform: translateX(0);
}
