@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Terms of Service Page Section */
.terms-of-service-page-section {
    position: relative;
    height: 60vh; /* Maintain height */
    background-image: url('./terms-of-service.jpg'); /* Background image */
    background-size: 60%; /* Initial zoomed-in size */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color for contrast */
    text-align: center; /* Center-align the text content */
    padding: 0 20px; /* Padding for smaller screens */
    animation: zoomEffect 2s forwards; /* Apply the one-time zoom effect */
}

.terms-of-service-banner-content h1 {
    font-size: 4rem; /* Large font size for the heading */
    color: white;
    font-family: 'Montserrat', sans-serif; /* Brand font */
    font-weight: 600; /* Semi-bold for a strong presence */
    line-height: 1.2; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below the heading */
}

/* Terms of Service Content Section */
.terms-of-service-section {
    padding: 50px;
    background-color: #f8f9fa; /* Light background for the section */
}

.terms-of-service-content {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    color: #333; /* Main text color */
    line-height: 1.8;
}

.terms-of-service-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.terms-of-service-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.terms-of-service-content a {
    color: #ff0066; /* Link color */
    text-decoration: none;
}

.terms-of-service-content a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .terms-of-service-page-section {
        height: auto; /* Allow height to adjust based on content */
        padding: 40px 20px; /* Adjust padding for smaller screens */
    }

    .terms-of-service-banner-content h1 {
        font-size: 2.5rem; /* Smaller font size for mobile */
    }

    .terms-of-service-content h2 {
        font-size: 1.75rem;
    }

    .terms-of-service-content p {
        font-size: 1rem;
    }
}
