/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* FAQ Page Section */
.faq-page-section {
  position: relative;
  height: 50vh;
  background-image: url('./FAQBanner.jpg'); /* Replace with appropriate banner image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 20px;
}

.faq-banner {
  max-width: 800px;
  margin: 0 auto;
}

.faq-banner h1 {
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}

/* FAQ Content Section */
.faq-content {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.faq-container {
  max-width: 900px;
  margin: 0 auto;
  border-top: 1px solid #ddd;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-item:hover {
  background-color: #f1f1f1;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  margin: 0;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #2c3e50;
}

.faq-question span {
  font-size: 1.5rem;
  color: #ff0066;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-item.active .faq-answer {
  max-height: 200px; /* Adjust height based on content */
  margin-top: 10px;
}

.faq-answer p {
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #555;
  line-height: 1.8;
  margin: 10px 0 0 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-page-section {
    height: 40vh;
  }

  .faq-banner h1 {
    font-size: 2.5rem;
  }

  .faq-container {
    padding: 0 20px;
  }
}
