/* Consistent Font Import (already included in the first file) */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Section Styles */
.our-sellers-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 40px;
    background-color: #f8f9fa;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.text-content {
    flex: 1;
    max-width: 48%;
    padding-right: 20px;
}

.text-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    color: #333;
}

.selling-point {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

.icon {
    font-size: 3rem;
    margin-right: 20px;
    color: #ff0066;
    min-width: 50px;
}

.selling-point h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #555;
}

.selling-point p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 0;
    color: #666;
}

.read-more-link {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    color: #ff0066;
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
}

.read-more-link .arrow {
    font-size: 1.5rem;
    margin-left: 10px;
    transition: margin-left 0.3s ease;
}

.read-more-link:hover .arrow {
    margin-left: 20px;
}

.image-content {
    flex: 1;
    max-width: 48%;
    display: flex;
    justify-content: center;
    padding-left: 20px;
}

.image-content img {
    width: 100%;
    height: auto; /* Adjusted to auto for better responsiveness */
    max-height: 600px; /* Ensure image doesn't exceed this height */
    border-radius: 15px;
    object-fit: cover;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-content img:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
    .our-sellers-section {
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
    }

    .text-content, .image-content {
        max-width: 100%;
        padding: 0;
    }

    .text-content {
        margin-bottom: 30px;
    }

    .text-content h2 {
        font-size: 2rem; /* Adjust font size for mobile */
        margin-bottom: 15px; /* Reduced margin for mobile */
    }

    .selling-point {
        flex-direction: column; /* Stack icon and text vertically on mobile */
        align-items: flex-start; /* Align items to the start */
        margin-bottom: 20px; /* Reduced space between items */
    }

    .icon {
        font-size: 2.5rem; /* Adjust icon size for mobile */
        margin-right: 0; /* Remove margin-right */
        margin-bottom: 10px; /* Space below the icon */
    }

    .selling-point h3 {
        font-size: 1.4rem; /* Smaller font size for mobile */
    }

    .selling-point p {
        font-size: 1rem; /* Smaller text size for mobile */
        line-height: 1.6; /* Adjusted line height for better readability */
    }

    .read-more-link {
        font-size: 0.9rem; /* Smaller font size for mobile */
    }

    .read-more-link .arrow {
        font-size: 1.2rem; /* Smaller arrow size */
    }

    .image-content img {
        max-height: 400px; /* Reduced height for mobile */
    }
}

/* AOS Animations */
[data-aos="fade-right"] {
    opacity: 0;
    transform: translateX(-50px);
    transition-property: opacity, transform;
}

[data-aos="fade-left"] {
    opacity: 0;
    transform: translateX(50px);
    transition-property: opacity, transform;
}

[data-aos].aos-animate {
    opacity: 1;
    transform: translateX(0);
}
