/* src/BlogComingSoon.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Keyframes for Zoom Effect */
@keyframes zoomEffect {
    0% {
        background-size: 80%;
        background-position: center;
    }
    100% {
        background-size: 100%;
        background-position: center;
    }
}

/* Blog Page Section Header */
.blog-page-section {
    position: relative;
    height: 60vh;
    background-image: url('./Blog.jpg'); /* Add your blog header background image here */
    background-size: cover; /* Adjusted for better responsiveness */
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 0 20px;
    animation: zoomEffect 2s forwards;
}

.blog-content {
    max-width: 800px;
    margin: 0 auto;
}

.blog-content h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
}

/* Coming Soon Section */
.coming-soon-section {
    display: flex;
    flex-direction: column; /* Stacks content vertically */
    justify-content: center;
    align-items: center;
    height: 40vh;
    background-color: #f9f9f9;
    text-align: center;
    padding: 20px;
}

.coming-soon-content {
    max-width: 600px;
}

.coming-soon-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.coming-soon-content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Blog Page Section Header */
    .blog-page-section {
        height: 40vh; /* Reduced height for mobile */
        background-size: cover; /* Ensure image covers the section */
    }

    .blog-content h1 {
        font-size: 2.5rem; /* Smaller font size for mobile */
        margin-bottom: 15px; /* Reduced margin for mobile */
    }

    /* Coming Soon Section */
    .coming-soon-section {
        height: auto; /* Allow height to adjust based on content */
        padding: 20px 10px; /* Adjusted padding for mobile */
    }

    .coming-soon-content h2 {
        font-size: 2rem; /* Smaller font size for mobile */
        margin-bottom: 15px; /* Reduced margin for mobile */
    }

    .coming-soon-content p {
        font-size: 1rem; /* Smaller text size for mobile */
        line-height: 1.6; /* Adjusted line height for readability */
    }
}
