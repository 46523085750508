/* Importing custom font */
@import url('https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap');

/* Navbar Styling */
.custom-navbar {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 30px; /* Padding for spacing */
  display: flex;
  justify-content: space-between; /* Space out left and right sections */
  align-items: center; /* Center items vertically */
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 60px; /* Logo size */
  margin-right: 20px; /* Space between the logo and "Panache Fashion" text */
}

.navbar-brand-text {
  font-family: 'Monsieur La Doulaise', cursive; /* Use Monsieur La Doulaise font */
  font-size: 2.5rem; /* Larger text size */
  font-weight: 599; /* Bold text */
  color: #ff0066; /* Brand color */
  margin-right: 140px; /* Push language switcher to the end */
}

.nav-links-left .nav-link {
  margin-right: 25px; /* Spacing between left-side nav items */
}

.nav-links-right .nav-link {
  margin-right: 30px; /* Spacing between "Katalog Tkanin" and the language switcher */
}

/* Language Switcher Styling */
.language-switcher {
  position: relative;
  display: flex;
  align-items: center; /* Align vertically with other nav items */
}

.language-switcher .dropdown-toggle {
  background: none;
  border: none;
  color: #ff0066;
  font-size: 1.1rem; /* Font size */
  padding: 0;
  cursor: pointer;
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth color transition */
}

.language-switcher .dropdown-toggle:hover,
.language-switcher .dropdown-toggle:focus {
  color: #e6005c; /* Darker brand color for hover and focus */
}

/* Dropdown Menu Styling */
.language-switcher .dropdown-menu {
  position: absolute;
  top: 100%; /* Position below the toggle button */
  right: 0;
  min-width: 100px; /* Adjust the width if needed */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: none; /* Hide by default */
}

.language-switcher .dropdown-menu.show {
  display: block; /* Show on hover */
}

.language-switcher .dropdown-item {
  padding: 10px 15px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.language-switcher .dropdown-item:hover {
  background-color: #f8f9fa; /* Light background on hover */
  color: #ff0066; /* Brand color for text on hover */
}

/* Navbar Link Styling */
.navbar-nav .nav-link {
  color: #ff0066;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #ff0066; /* Underline color */
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover::after {
  visibility: visible;
  width: 100%;
}

.navbar-nav .nav-link:hover {
  color: #ff0066;
}

.navbar-nav .nav-link:focus {
  outline: none;
}

/* Mobile Navbar / Hamburger Menu Styling */


/* Responsive Design */
/* Language Switcher Styling */
.language-switcher {
    position: relative;
    display: flex;
    align-items: center; /* Align vertically with other nav items */
  }
  
  .language-switcher .dropdown-toggle {
    background: none;
    border: none;
    color: #ff0066;
    font-size: 1.1rem; /* Font size */
    padding: 0;
    cursor: pointer;
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .language-switcher .dropdown-toggle:hover,
  .language-switcher .dropdown-toggle:focus {
    color: #e6005c; /* Darker brand color for hover and focus */
  }
  
  /* Dropdown Menu Styling */
  .language-switcher .dropdown-menu {
    position: absolute;
    top: 100%; /* Position below the toggle button */
    left: 50%;
    transform: translateX(-50%); /* Center align the dropdown under the toggle */
    max-width: 100px; /* Adjust the width if needed */
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: none; /* Hide by default */
    z-index: 1000; /* Ensure it appears above other content */
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth appearance */
  }
  
  .language-switcher .dropdown-menu.show {
    display: block; /* Show on hover */
    opacity: 1;
    transform: translateX(-50%) translateY(10px); /* Drop down smoothly */
  }
  
  .language-switcher .dropdown-item {
    padding: 10px 10px;
    color: #333;
    cursor: pointer;
    text-align: center; /* Center text in dropdown */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .language-switcher .dropdown-item:hover {
    background-color: #f8f9fa; /* Light background on hover */
    color: #ff0066; /* Brand color for text on hover */
  }
  
  /* Mobile Specific Styles */
/* Mobile Specific Styles */
@media (max-width: 768px) {
    /* Ensure the language switcher remains visible and the dropdown opens smoothly underneath */
    .language-switcher {
      position: relative;
      z-index: 1; /* Keep language switcher on top */
    }
  
    .language-switcher .dropdown-menu {
      position: absolute;
      top: calc(100% + 5px); /* Slightly below the language switcher */
      left: 10px !important;
      right: 0;
      width: 100%; /* Full width dropdown on mobile */
      min-width: auto; /* No minimum width for better mobile fitting */
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: none; /* Hidden by default */
      opacity: 0;
      transform: translateY(10px); /* Start slightly offset */
      transform: translateX(10px) !important;
      transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth dropdown appearance */
    }
  
    .language-switcher .dropdown-menu.show {
      display: block; /* Display dropdown */
      opacity: 1;
      transform: translateY(0); /* Animate to visible position */
    }
  
    .language-switcher .dropdown-item {
      padding: 10px 15px;
      color: #333;
      text-align: center; /* Center align text */
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  
    .language-switcher .dropdown-item:hover {
      background-color: #f8f9fa; /* Light background on hover */
      color: #ff0066; /* Brand color for text on hover */
    }
  
    /* Adjust the navbar padding for better fit on mobile */
    .custom-navbar {
      padding: 10px 20px;
    }
  
    /* Keep the brand text size manageable on mobile */
    .navbar-brand-text {
      margin-right: 0; /* Remove extra margin */
      font-size: 2rem; /* Adjust text size */
    }
  }