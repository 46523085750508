/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Cookie Banner Styling */
.cookie-banner {
    background-color: #ffffff !important; /* White background for a clean, modern look */
    color: #2c3e50 !important; /* Dark gray text for readability and consistency */
    font-family: 'Montserrat', sans-serif !important; /* Brand font */
    font-size: 1rem !important; /* Base font size */
    padding: 20px !important; /* Padding for spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow for depth */
    border-top: 3px solid #2c3e50 !important; /* Dark gray for a refined touch */
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important; /* Center align content vertically */
    border-radius: 8px !important; /* Rounded corners for a softer look */
    max-width: 800px !important; /* Restrict width to match content area */
    margin: 20px auto !important; /* Center the banner horizontally */
}

.cookie-banner__content {
    margin: 0 !important; /* Remove default margin */
    padding-right: 20px !important; /* Space between text and buttons */
    flex: 1 !important; /* Take up remaining space */
    font-weight: 300 !important; /* Light font weight for readability */
    line-height: 1.6 !important; /* Comfortable line spacing */
    color: #555 !important; /* Softer gray for a professional look */
}

.cookie-banner__button {
    background-color: #ff0066 !important; /* Brand color for buttons */
    color: #ffffff !important; /* White text */
    font-family: 'Montserrat', sans-serif !important; /* Consistent font family */
    font-weight: 600 !important; /* Semi-bold font weight */
    border: none !important; /* No border */
    border-radius: 5px !important; /* Rounded corners */
    padding: 10px 20px !important; /* Padding for the button */
    cursor: pointer !important; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease !important; /* Smooth transition for hover */
}

.cookie-banner__button:hover {
    background-color: #e6005c !important; /* Darker shade of brand color on hover */
    transform: scale(1.05) !important; /* Slight enlargement on hover */
}

.cookie-banner__decline-button {
    background-color: transparent !important; /* Transparent background */
    color: #ff0066 !important; /* Brand color for decline button */
    border: 2px solid #ff0066 !important; /* Border in brand color */
    border-radius: 5px !important; /* Rounded corners */
    padding: 10px 20px !important; /* Padding for the button */
    cursor: pointer !important; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease !important; /* Smooth transition for hover */
}

.cookie-banner__decline-button:hover {
    background-color: #ff0066 !important; /* Fill background on hover */
    color: #ffffff !important; /* White text on hover */
    transform: scale(1.05) !important; /* Slight enlargement on hover */
}

/* Mobile Specific Styles */
/* Mobile Specific Styles */
/* Mobile Specific Styles */
/* Mobile Specific Styles */
@media (max-width: 768px) {
    .cookie-banner {
        height: 150px !important; /* Set fixed height */
        padding: 8px 10px !important; /* Minimal padding */
        max-width: 100% !important; /* Full width on mobile */
        display: flex !important;
        flex-direction: row !important; /* Elements placed in a row */
        justify-content: center !important; /* Center everything horizontally */
        align-items: center !important; /* Vertically center content */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow */
        border-radius: 8px !important; /* Rounded corners */
    }

    .cookie-banner__content {
        margin: 0 !important; /* Remove margin */
        padding: 0 10px !important; /* Padding between content and buttons */
        font-size: 0.75rem !important; /* Smaller font size */
        line-height: 1.2 !important; /* Compact line height */
        text-align: left !important; /* Align text to the left */
        flex: 1 1 auto !important; /* Allow content to grow/shrink as needed */
    }

    .cookie-banner__buttons {
        display: flex !important;
        flex-direction: row !important; /* Keep buttons in a row */
        justify-content: center !important; /* Center the buttons */
        gap: 10px !important; /* Larger gap between buttons */
        margin-left: 10px !important; /* Space between content and buttons */
        flex-shrink: 0 !important; /* Prevent buttons from shrinking */
    }

    .cookie-banner__button,
    .cookie-banner__decline-button {
        padding: 8px 16px !important; /* Increase padding for larger buttons */
        font-size: 0.85rem !important; /* Slightly larger font size */
        flex: 0 1 auto !important; /* Allow buttons to take necessary space */
        max-width: 120px !important; /* Ensure buttons are a bit wider */
        box-sizing: border-box !important; /* Include padding/border in size */
    }
}