/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* About Us Section */
.about-us-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    background-color: #f8f9fa;
}

.about-us-content {
    flex: 1;
    padding-right: 20px;
    max-width: 50%;
}

.about-us-content h2 {
    font-family: 'Playfair Display', serif; /* Updated font */
    font-size: 2.5rem;
    font-weight: 600; /* Adjusted to a slightly less bold weight */
    color: #2c3e50; /* More elegant color */
    margin-bottom: 20px;
}

.about-us-content p {
    font-family: 'Montserrat', sans-serif; /* Updated font */
    font-size: 1.2rem;
    line-height: 1.8; /* Increased for better readability */
    color: #555; /* A softer gray for a professional look */
    margin-bottom: 20px;
}

.read-more-link {
    font-family: 'Montserrat', sans-serif; /* Consistent font */
    font-weight: 600; /* A bit bolder */
    font-size: 1rem;
    text-decoration: none;
    color: #ff0066;
    display: inline-flex;
    align-items: center;
}

.read-more-link .arrow {
    font-size: 1.5rem;
    margin-left: 10px;
    transition: margin-left 0.3s ease;
}

.read-more-link:hover .arrow {
    margin-left: 20px;
}

.about-us-image {
    flex: 1;
    max-width: 50%;
}

.about-us-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Mobile Styles */
@media (max-width: 768px) {
    .about-us-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px; /* Adjusted padding for smaller screens */
    }

    .about-us-content,
    .about-us-image {
        max-width: 100%;
        padding: 0;
    }

    .about-us-content {
        padding-bottom: 20px; /* Space between content and image on smaller screens */
    }

    .about-us-content h2 {
        font-size: 1.8rem; /* Smaller font size for mobile */
        margin-bottom: 15px; /* Reduced space below the heading */
    }

    .about-us-content p {
        font-size: 1rem; /* Slightly smaller text for mobile */
        line-height: 1.6; /* Adjusted line height for better readability */
    }

    .read-more-link {
        font-size: 0.9rem; /* Smaller font size for mobile */
    }

    .read-more-link .arrow {
        font-size: 1.2rem; /* Smaller arrow size */
    }
}

/* AOS Animations */
[data-aos="fade-right"] {
    opacity: 0;
    transform: translateX(-50px);
    transition-property: opacity, transform;
}

[data-aos="fade-left"] {
    opacity: 0;
    transform: translateX(50px);
    transition-property: opacity, transform;
}

[data-aos].aos-animate {
    opacity: 1;
    transform: translateX(0);
}
