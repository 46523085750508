  /* src/HomePage.css */

/* Hero Section Styling */
.hero-section {
    position: relative;
    height: 100vh;
    background-image: url('hero_background.png'); /* Path to your hero image */
    background-position: center;
    background-size: cover; /* Cover the entire hero section */
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding: 20px 40px;
    background-color: rgba(0, 0, 0, 0.5); /* Translucent black background */
    border-radius: 10px;
    max-width: 80%; /* Make sure the banner doesn't stretch too wide */
  }
  
  /* First line "ZAPRASZAMY DO" */
  .overlay-text h1 {
    font-family: 'Playfair Display', serif;
    font-size: 5rem; /* Increase font size for the first line */
    font-weight: 400;
    margin: 0;
    color: #ffffff; /* Keep it white */
  }
  
  /* Second line "ŚWIATA TKANIN" */
  .overlay-text h1 span {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 8rem; /* Larger size for the second line */
    font-weight: 900; /* Extra bold for prominence */
    color: #ffcc00; /* Contrast color */
    margin-top: 10px;
    text-transform: uppercase;
  }
  
  .overlay-text h1 span::before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    background-color: #ffcc00; /* Match the text color */
    margin: 10px auto;
    border-radius: 5px;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .overlay-text {
      padding: 15px 30px;
    }
  
    .overlay-text h1 {
      font-size: 2.5rem; /* Smaller font size for mobile */
    }
  
    .overlay-text h1 span {
      font-size: 3.5rem; /* Smaller font size for mobile */
    }
  }
  
  