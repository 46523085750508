/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* Keyframes for Zoom Effect */
@keyframes zoomEffect {
    0% {
        background-size: 80%; /* Start zoomed in */
        background-position: center; /* Center the image */
    }
    100% {
        background-size: 100%; /* End zoomed out */
        background-position: center; /* Ensure the final position is centered */
    }
}

/* Services Page Section */
.services-page-section {
    position: relative;
    height: 60vh; /* Maintain height */
    background-image: url('./banner.jpg'); /* Background image */
    background-size: cover; /* Cover the section */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    display: flex;
    justify-content: center;
    align-items: center; /* Center content vertically */
    color: white; /* Text color for contrast */
    text-align: center; /* Center-align the text content */
    padding: 20px; /* Padding for smaller screens */
    animation: zoomEffect 2s forwards; /* Apply the one-time zoom effect */
}

.services-content {
    max-width: 800px; /* Limit content width for readability */
    margin: 0 auto; /* Center content horizontally */
}

.services-content h1 {
    font-size: 4rem; /* Large font size for the heading */
    font-family: 'Montserrat', sans-serif; /* Brand font */
    font-weight: 600; /* Semi-bold for a strong presence */
    line-height: 1.2; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below the heading */
}

.services-content p {
    font-size: 1.2rem; /* Slightly larger text for readability */
    font-family: 'Montserrat', sans-serif; /* Consistent font family */
    font-weight: 300; /* Light font weight */
    line-height: 1.8; /* Comfortable line spacing */
    margin-bottom: 20px; /* Space below paragraphs */
}

/* Czym się zajmujemy Section */
.services-what-we-do-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; /* Align content to the top */
    padding: 50px;
    background-color: #f9f9f9;
    position: relative;
}

.services-what-we-do-content {
    flex: 1;
    max-width: 45%;
    font-family: 'Montserrat', sans-serif;
    padding-right: 20px;
    z-index: 2;
}

.services-what-we-do-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.services-what-we-do-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.services-what-we-do-image {
    flex: 1;
    max-width: 48%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.services-what-we-do-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Sprzedaż Section Styling */
.services-sale-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; /* Align content to the top */
    padding: 50px;
    background-color: #ffffff; /* White background */
    position: relative;
}

.services-sale-content {
    flex: 1;
    max-width: 45%;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px; /* Content padding on the left */
    z-index: 2;
    order: 1;
}

.services-sale-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.services-sale-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.services-sale-image {
    flex: 1;
    max-width: 40%;
    height: 100%;
    position: relative;
    overflow: hidden;
    order: 0;
}

.services-sale-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Darmowe próbki Section */
.services-sample-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; /* Align content to the top */
    padding: 50px;
    background-color: #f9f9f9;
}

.services-sample-image {
    flex: 1;
    max-width: 35%;
    height: 100%;
    position: relative;
    overflow: hidden;
    order: 1;
}

.services-sample-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.services-sample-content {
    flex: 1;
    max-width: 48%;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    order: 0;
}

.services-sample-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.services-sample-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.contact-button {
    font-family: 'Montserrat', sans-serif; /* Consistent font */
    font-weight: 600; /* A bit bolder */
    font-size: 1rem;
    text-decoration: none;
    color: #ff0066;
    display: inline-flex;
    align-items: center;
}

.contact-button .arrow {
    font-size: 1.5rem;
    margin-left: 10px;
    transition: margin-left 0.3s ease;
}

.contact-button:hover .arrow {
    margin-left: 20px; /* Slide the arrow on hover */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .services-page-section {
        height: 40vh; /* Reduced height for mobile */
        background-size: cover; /* Ensure background image covers the section */
        padding: 10px; /* Reduced padding for mobile */
    }

    .services-content h1 {
        font-size: 2.5rem; /* Smaller font size for mobile */
        margin-bottom: 15px; /* Reduced margin for mobile */
    }

    .services-content p {
        font-size: 1rem; /* Smaller text size for mobile */
        line-height: 1.6; /* Adjusted line height for readability */
    }

    .services-what-we-do-section,
    .services-sale-section,
    .services-sample-section {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .services-what-we-do-content,
    .services-sale-content,
    .services-sample-content {
        max-width: 100%;
        padding: 0;
        margin-bottom: 20px; /* Space between content sections */
    }

    .services-what-we-do-image,
    .services-sale-image,
    .services-sample-image {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .services-sale-image {
        max-width: 100%;
    }
}

/* AOS Animations */
[data-aos="fade-right"] {
    opacity: 0;
    transform: translateX(-50px);
    transition-property: opacity, transform;
    transition-duration: 1s;
}

[data-aos="fade-left"] {
    opacity: 0;
    transform: translateX(50px);
    transition-property: opacity, transform;
    transition-duration: 1s;
}

[data-aos].aos-animate {
    opacity: 1;
    transform: translateX(0);
}
