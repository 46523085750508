@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');



.privacy-policy-page-section {
    position: relative;
    height: 60vh; /* Maintain height */
    background-image: url('./privacy-policy.jpg'); /* Use the same background image as About Us */
    background-size: 60%; /* Initial zoomed-in size */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color for contrast */
    text-align: center; /* Center-align the text content */
    padding: 0 20px; /* Padding for smaller screens */
    animation: zoomEffect 2s forwards; /* Apply the one-time zoom effect */
}

.privacy-policy-banner-content h1 {
    font-size: 4rem; /* Large font size for the heading */
    color: white;
    font-family: 'Montserrat', sans-serif; /* Brand font */
    font-weight: 600; /* Semi-bold for a strong presence */
    line-height: 1.2; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below the heading */
}

.privacy-policy-section {
  padding: 50px;
  background-color: #f8f9fa; /* Light background for the section */
}

.privacy-policy-content {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  color: #333; /* Main text color */
  line-height: 1.8;
}

.privacy-policy-content h1 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
}

.privacy-policy-content h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.privacy-policy-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.privacy-policy-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.privacy-policy-content ul li {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.privacy-policy-content a {
  color: #ff0066; /* Link color */
  text-decoration: none;
}

.privacy-policy-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy-content h1 {
    font-size: 2.5rem;
  }

  .privacy-policy-content h2 {
    font-size: 1.75rem;
  }

  .privacy-policy-content p, 
  .privacy-policy-content ul li {
    font-size: 1rem;
  }
}
