/* src/CatalogueComingSoon.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

/* General Styling */
.catalogue-coming-soon-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 50px 20px;
    background-color: #f9f9f9;
    color: #2c3e50;
    text-align: center;
}

.coming-soon-content {
    max-width: 800px;
    margin-bottom: 40px;
    margin-top: -20px; /* Move the "Katalog" text upwards */
}

.coming-soon-content h1 {
    font-size: 3.5rem;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    margin-bottom: 20px;
}

.coming-soon-content h2 {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #ff0066; /* Brand color for emphasis */
    margin-bottom: 20px;
    margin-top: 80px;
}

.coming-soon-content p {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
}

/* Additional Information Section */
.additional-info {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

.additional-info h3 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c3e50;
}

.additional-info ul {
    list-style: none;
    padding: 0;
}

.additional-info ul li {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.6;
    color: #2c3e50;
    position: relative;
    padding-left: 25px;
}

.additional-info ul li:before {
    content: "✓";
    color: #ff0066;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.5rem;
}
