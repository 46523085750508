/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');

.fabric-slider-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
    background-color: #ffffff;
}

.slider-container {
    flex: 1;
    max-width: 50%;
    padding-right: 20px;
}

.fabric-image {
    width: 100%;
    height: 800px; /* Default height */
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.text-section {
    flex: 1;
    max-width: 50%;
    padding-left: 20px; /* Added padding to separate text from image */
}

.text-section h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.text-section p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
}

.read-more-link {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    color: #ff0066;
    display: inline-flex;
    align-items: center;
}

.read-more-link .arrow {
    font-size: 1.5rem;
    margin-left: 10px;
    transition: margin-left 0.3s ease;
}

.read-more-link:hover .arrow {
    margin-left: 20px;
}

/* Slick slider fixes */
.slick-slider {
    display: table !important;
    table-layout: fixed !important;
    width: 100% !important;
}

/* Responsive design */
@media (max-width: 768px) {
    .fabric-slider-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
    }

    .slider-container,
    .text-section {
        max-width: 100%;
        padding: 0;
    }

    .text-section {
        padding-bottom: 20px;
        padding-left: 0;
    }

    .fabric-image {
        height: 300px; /* Reduced image height for mobile */
        border-radius: 8px;
    }

    .text-section h2 {
        font-size: 1.8rem;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .text-section p {
        font-size: 1rem;
        line-height: 1.6;
    }

    .read-more-link {
        font-size: 0.9rem;
    }

    .read-more-link .arrow {
        font-size: 1.2rem;
    }
}

/* AOS Animations */
[data-aos="fade-right"] {
    opacity: 0;
    transform: translateX(-50px);
    transition-property: opacity, transform;
}

[data-aos="fade-left"] {
    opacity: 0;
    transform: translateX(50px);
    transition-property: opacity, transform;
}

[data-aos].aos-animate {
    opacity: 1;
    transform: translateX(0);
}
