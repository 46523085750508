/* Import Google Fonts and Font Awesome */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Playfair+Display:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/* Main Container */
.contact-page-section {
    position: relative;
    height: 60vh; /* Maintain height */
    background-image: url('./contact-photo-1.png'); /* Background image */
    background-size: cover; /* Ensure background image covers the section */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of the image */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color for contrast */
    text-align: center; /* Center-align the text content */
    padding: 0 20px; /* Padding for smaller screens */
    animation: zoomEffect 2s forwards; /* Apply the one-time zoom effect */
}

.contact-us-content {
    max-width: 800px; /* Limit content width for readability */
    margin: 0 auto; /* Center content horizontally */
}

.contact-us-content h1 {
    font-size: 4rem; /* Large font size for the heading */
    font-family: 'Montserrat', sans-serif; /* Brand font */
    font-weight: 600; /* Semi-bold for a strong presence */
    line-height: 1.2; /* Adjust line height for readability */
    margin-bottom: 20px; /* Space below the heading */
}

.contact-us-content p {
    font-size: 1.2rem; /* Slightly larger text for readability */
    font-family: 'Montserrat', sans-serif; /* Consistent font family */
    font-weight: 300; /* Light font weight */
    line-height: 1.8; /* Comfortable line spacing */
    margin-bottom: 20px; /* Space below paragraphs */
}

.contact-us-container {
    padding: 50px;
    width: 100%;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Contact Information Section */
.contact-info {
    font-family: 'Montserrat', sans-serif;
    color: #2c3e50;
    max-width: 55%;
}

.contact-info h1 {
    font-size: 2.5rem;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    margin-bottom: 30px; /* Increased space below the heading */
}

.contact-info h3 {
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px; /* Increased space below subheading */
    font-weight: 600;
}

.contact-info p {
    font-size: 1.1rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.8;
    margin-bottom: 20px; /* Increased space below paragraphs */
    font-weight: 300;
}

.contact-methods p {
    margin-bottom: 20px; /* Increased space between contact methods */
    font-weight: 400;
    display: flex;
    align-items: center;
}

.contact-methods i {
    margin-right: 15px; /* Increased space between icon and text */
    color: #ff0066;
}

/* Link Styling for Phone and Email */
.contact-methods a {
    color: inherit; /* Inherit color from the parent element */
    text-decoration: none; /* Remove underline */
}

.contact-methods a:hover {
    color: #ff0066; /* Optional: Change color on hover */
    text-decoration: underline; /* Optional: Add underline on hover */
}

/* Contact Form Section */
.contact-form {
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 45%;
    flex: 1;
    position: relative;
}

.contact-form h2 {
    font-size: 2rem;
    font-family: 'Playfair Display', serif;
    margin-bottom: 30px; /* Increased space below form title */
    color: #2c3e50;
}

.contact-form input, 
.contact-form textarea {
    width: 100%;
    padding: 12px; /* Increased padding for form fields */
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px; /* Increased space below form fields */
}

/* Center the submit button */
.contact-form button {
    padding: 12px 25px; /* Increased padding for the button */
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    background-color: #ff0066;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 20px auto 0; /* Centered horizontally */
}

.contact-form button:hover {
    background-color: #e6005a;
}

/* Styling for the checkbox */
.contact-form .consent {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contact-form .consent input {
    margin-right: 15px; /* Increased space between checkbox and label */
    margin-top: 2px; /* Adjust vertical alignment */
    width: 20px; /* Larger checkbox */
    height: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    appearance: none;
    cursor: pointer;
    position: relative;
}

.contact-form .consent input:checked::before {
    content: "\f00c"; /* FontAwesome checkmark */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact-form .consent input:checked {
    background-color: #ff0066;
    border-color: #ff0066;
}

.contact-form .consent label {
    flex: 1;
    font-size: 0.9rem;
    line-height: 1.5;
}

/* Form submission message styling */
.contact-form .form-submitted {
    font-size: 1.1rem;
    color: #28a745;
    text-align: center;
    margin-top: 20px;
    animation: fadeInOut 3s ease forwards;
}

/* Animation for form submission */
@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

/* Google Maps Section */
.google-maps {
    margin-top: 40px; /* Increased top margin */
    width: 100%;
    height: 450px;
}

.google-maps iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-page-section {
        height: 40vh; /* Reduced height for mobile */
        padding: 10px; /* Reduced padding for mobile */
    }

    .contact-us-content h1 {
        font-size: 2.5rem; /* Smaller font size for mobile */
        margin-bottom: 15px; /* Reduced margin for mobile */
    }

    .contact-us-content p {
        font-size: 1rem; /* Smaller text size for mobile */
        line-height: 1.6; /* Adjusted line height for readability */
    }

    .contact-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-info {
        max-width: 100%; /* Full width for mobile */
        margin-bottom: 20px; /* Space below contact info */
    }

    .contact-form {
        max-width: 100%; /* Full width for mobile */
        margin-top: 20px;
    }

    .google-maps {
        height: 300px; /* Adjusted height for mobile */
    }
}

/* Very Small Devices */
@media (max-width: 480px) {
    .contact-page-section {
        height: 30vh; /* Further reduced height for very small screens */
        padding: 5px; /* Further reduced padding */
    }

    .contact-us-content h1 {
        font-size: 2rem; /* Even smaller font size */
        margin-bottom: 10px; /* Reduced margin */
    }

    .contact-us-content p {
        font-size: 0.9rem; /* Smaller text size */
        line-height: 1.5; /* Adjusted line height */
    }

    .contact-info h1 {
        font-size: 2rem; /* Smaller font size for the heading */
        margin-bottom: 20px; /* Reduced space below heading */
    }

    .contact-info h3 {
        font-size: 1.2rem; /* Smaller subheading font size */
    }

    .contact-info p {
        font-size: 1rem; /* Smaller paragraph text size */
    }

    .contact-form input, 
    .contact-form textarea {
        padding: 10px; /* Reduced padding for form fields */
        font-size: 0.9rem; /* Smaller font size */
    }

    .contact-form button {
        padding: 10px 20px; /* Reduced padding for the button */
        font-size: 1rem; /* Smaller button font size */
    }
}
